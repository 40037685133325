import React from "react"
import PostItem from "../Post/PostItem"
// import useAllPost from "../../hooks/use-all-blog-post"

const ProductFeed = (props) => {
  // const allPost = useAllPost()
  const { allPost, start, numberOfDisplaysPerpage } = props

  return allPost.slice(start, start + numberOfDisplaysPerpage).map((node, index) => {
    return <PostItem key={index} node={node} />
  })
}

export default ProductFeed
