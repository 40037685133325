import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
// import ProductFeed from "../components/Feeds/ProductFeed"
import PostFeed from "../components/Feeds/PostFeed"
import useAllPost from "../hooks/use-all-blog-post"
import Pagination from "../components/Pagination"

const FeedTemplate = (contentfulPage) => {
  const allPost = useAllPost()
  const headerImage = getImage(contentfulPage.headerImage)
  const [start, setStart] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const numberOfDisplaysPerpage = 6

  const getTemplate = (contentfulPage) => {
    const { feedType } = contentfulPage
  
    switch (feedType) {
      case "Products":
        // return <ProductFeed {...contentfulPage} />
        break
  
      default:
        return <PostFeed {...contentfulPage} allPost={allPost} start={start} numberOfDisplaysPerpage={numberOfDisplaysPerpage} />
    }
  }
  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title}>
          <GatsbyImage
            className="banner__image"
            image={headerImage}
            alt={`${contentfulPage.title} feed`}
          />
        </SimpleBanner>
        <div className="section">
          <div className="feed">{getTemplate(contentfulPage)}</div>
          <Pagination
            dataCounts={allPost.length}
            setStart={setStart}
            numberOfDisplaysPerpage={numberOfDisplaysPerpage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Layout>
    </>
  )
}

export default FeedTemplate
